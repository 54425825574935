<template>
  <div class="choice-file">
    <div class="dialog-container">
      <el-dialog
        v-model="dirFormVisible"
        title="选择目录"
        :close-on-click-modal="false"
        :before-close="handleClose"
        width="60%"
      >
        <div v-loading="loadingDialog" class="dialog-content">
          <div class="left">
            <el-tree
              ref="tree"
              :data="data"
              :load="loadNode"
              lazy
              node-key="path"
              :default-expanded-keys="defaultExpandedKeys"
              :props="defaultProps"
              icon-class="el-icon-arrow-right"
              @node-click="handleNodeClick"
            >
              <template #default="{ node }">
                <span class="custom-tree-node">
                  <span><svg-icon
                    style="margin: 0 12px"
                    icon-class="files"
                  /></span>
                  <span>{{ node.label }}</span>
                </span>
              </template>
            </el-tree>
          </div>
          <div class="right">
            <el-table
              ref="multipleTable"
              :data="filesData"
              tooltip-effect="dark"
              style="width: 100%"
              :row-style="tableCellStyle"
              :row-class-name="tableRowClassName"
              @row-click="rowClick"
              @row-dblclick="rowDbClick"
              @cell-mouse-enter="mousePass"
            >
              <el-table-column prop="name" label="名称" show-overflow-tooltip />
              <el-table-column prop="size" label="大小" show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.size }}Bytes
                </template>
              </el-table-column>
              <el-table-column prop="type" label="文件类型" show-overflow-tooltip>
                <template #default="scope">
                  <div v-if="scope.row.type === ''" style="display: inline-block"><svg-icon icon-class="file" /></div>
                  <div v-else-if="scope.row.type === 'cmd'" style="display: inline-block"><svg-icon icon-class="cmd" /></div>
                  <div v-else-if="scope.row.type === 'md'" style="display: inline-block"><svg-icon icon-class="md" /></div>
                  <div v-else-if="scope.row.type === 'text'" style="display: inline-block"><svg-icon icon-class="txt" /></div>
                  <div v-else-if="scope.row.type === 'zip'" style="display: inline-block"><svg-icon icon-class="zip" /></div>
                  <div v-else-if="scope.row.type === 'image'" style="display: inline-block"><svg-icon icon-class="img" /></div>
                  <div v-else><svg-icon icon-class="other" /></div>
                </template>
              </el-table-column>
              <el-table-column
                prop="modified"
                label="修改日期"
                :formatter="formatTime"
                show-overflow-tooltip
              />
            </el-table>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              type="primary"
              @click="confirmChoice"
            >确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import {
  filesList,
  dirsList
} from '@/api/file'
import store from '@/store'
export default {
  props: ['visible', 'type'],
  data() {
    return {
      defaultExpandedKeys: [],
      currentRow: -1,
      loadingDialog: false,
      filesData: [],
      data: [],
      path: '',
      defaultProps: {
        children: 'childDir',
        label: 'name'
      },
      dirFormVisible: this.visible,
      choiceType: 'dir',
      directory: '',
      choiceDir: ''
    }
  },
  watch: {
    visible(newValue, oldValue) {
      this.dirFormVisible = newValue
      if (this.type === 'dir') {
        this.path = '/public/home/' + store.getters.name + '/'
        this.getFiles()
      }
    },
    type(n, o) {
      console.log(n)
      this.choiceType = n
      this.getFiles()
    }
  },
  mounted() {
    // this.getDirsList()
    this.getFiles()
    this.directory = '/public/home/' + store.getters.name
    this.path = this.directory + '/'
    this.defaultExpandedKeys.push(this.directory)
  },
  methods: {
    // 时间格式
    // 时间格式化
    formatTime(row, column) {
      const data = row[column.property]
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    mousePass(row, column, cell, event) {
      this.currentRow = row.index
    },
    clickDir() {
      this.dirFormVisible = false
    },
    handleClose() {
      if (this.choiceType === 'dir') {
        this.$emit('confirmDirs', this.choiceDir, false, this.choiceType)
      } else {
        this.$emit('confirmDirs', this.choiceFile, false, this.choiceType)
      }
    },
    // 确定选择目录
    confirmChoice() {
      if (this.choiceType === 'dir') {
        if (this.choiceDir === '') {
          ElMessage.warning('未选择目录！请点击选择您所需要的目录！')
          return
        }
        this.$emit('confirmDirs', this.choiceDir, false, this.choiceType)
      } else {
        if (this.choiceFile === '') {
          ElMessage.warning('未选择文件！请点击选择您所需要的文件！')
          return
        }
        this.$emit('confirmDirs', this.choiceFile, false, this.choiceType)
      }
      this.$nextTick(() => {
        this.choiceDir = ''
        this.choiceFile = ''
      })
    },
    // // 文件夹列表
    // getDirsList() {
    //   this.loadingDialog = true
    //   dirsList(this.path, true).then((response) => {
    //     const data = [
    //       { name: response.data.name, path: response.data.path, id: 1, childDir: [] }
    //     ]
    //     data[0].childDir = response.data.childDir
    //     this.data = data
    //     this.loadingDialog = false
    //   })
    // },
    // 递归列表最后一级为空
    getTreeData(data, child) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].childDir === undefined) {
          // children若为空数组，则将children设为undefined
          data[i].childDir = []
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].childDir)
        }
      }
      return data
    },
    // 数组去重
    unique(arr) {
      const res = new Map()
      return arr.filter((arr) => !res.has(arr) && res.set(arr, 1))
    },
    // loadNode 加载tree节点
    loadNode(node, resolve) {
      this.loadingDialog = true
      if (node.data.path !== undefined) {
        this.path = node.data.path
      }
      filesList(this.path, true).then((response) => {
        const dirs = []
        if (this.choiceType === 'dir') {
          response.data.items.forEach(item => {
            if (item.isDir) {
              dirs.push(item)
            }
          })
          this.filesData = dirs
        } else {
          this.filesData = response.data.items
        }
        if (node.level === 0) {
          this.loadingDialog = false
          return resolve([
            {
              name: response.data.name,
              path: response.data.path,
              childDir: []
            }
          ])
        }
        var list = []
        response.data.items.forEach((e) => {
          if (e.isDir) {
            node.data.childDir.push(e)
            response.data.items.forEach((e) => {
              if (e.isDir) {
                list.push(e)
              }
            })
          }
        })
        list = this.unique(list)
        node.childNodes = []
        if (resolve) {
          resolve(this.getTreeData(list))// 动态加载时
          this.loadingDialog = false
        } else {
          // 更新节点时：
          node.childNodes = []
          node.doCreateChildren(this.getTreeData(list))
          this.loadingDialog = false
        }
      })
    },
    // 文件列表
    getFiles() {
      // if (this.data.length !== 0) {
      //   this.loadingDialog = true
      // }
      filesList(this.path, true)
        .then((response) => {
          const dirs = []
          if (this.choiceType === 'dir') {
            response.data.items.forEach(item => {
              if (item.isDir) {
                dirs.push(item)
              }
            })
            this.filesData = dirs
            this.loadingDialog = false
          } else {
            this.filesData = response.data.items
            this.loadingDialog = false
          }
        })
    },
    // 点击节点事件
    handleNodeClick(data) {
      this.path = data.path
      if (this.path.substr(this.path.length - 1, 1) === '/') {
        this.choiceDir = this.path.slice(0, this.path.length - 1)
      } else {
        this.choiceDir = this.path
      }
      this.$nextTick(() => {
        this.getFiles()
      })
    },
    rowClick(row) {
      this.row = row
      if (this.choiceType === 'dir') {
        if (this.row.isDir !== true) {
          ElMessage.warning('该数据不是目录，请选择正确的目录')
        } else {
          this.choiceDir = this.row.path
        }
      } else {
        this.choiceFile = this.row.path
        this.choiceDir = this.row.path
      }
    },
    tableCellStyle({ row }) {
      if (this.choiceDir === row.path) {
        return 'background-color:#e2e8f4;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 打开新的文件夹或者下载文件
    rowDbClick(row) {
      if (row.isDir) {
        this.path = row.path
        this.getFiles()
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog{
  border-radius: 20px;
}
::v-deep .el-dialog__footer {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 99;
  width: calc(100% - 230px);
  background-color: #fff;
  border-bottom-right-radius: 20px;
}
::v-deep .el-button--primary{
    padding: 9px 45px;
    color: #fff;
   }
::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-icon{
      color: #333;
    }
    ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
  ::v-deep .el-dialog{
    max-height: 600px;
    .el-dialog__body{
      max-height: 600px;
    }
  }
  .dialog-content{
    display: flex;
    font-size: 12px;
    .left {
      overflow: auto;
      min-width: 220px;
      // width: 100%;
      // height: 80%;
      max-height: calc(600px - 57px);
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      padding: 20px;
      color: #313a46;
      border-right: 1px solid #d8d8d8;
      ::v-deep .el-tree-node.is-expanded > .el-tree-node__children {
        display: inline;
      }
    }
    .right {
      width: 100%;
      padding: 20px;
      max-height: calc(600px - 127px);
      color: #202020;
      margin-bottom: 70px;
      ::v-deep .el-table {
        height: 100%;
        overflow: auto;
      }
    }
  }
</style>
