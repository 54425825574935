import axios from 'axios'
// import { MessageBox } from 'element-plus'
import { ElMessageBox, ElMessage } from 'element-plus'
import store from '@/store'
import { getToken, getRouteName } from '@/utils/auth'
import { Decrypt } from '@/utils/cryptoKey'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_FILE, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    // var CancelToken = axios.CancelToken
    // const source = CancelToken.source() // 申明CancelToken,也可new CancelToken.source()实例一个
    // config.cancelToken = source.token // 讲实例对象的token赋予该请求
    // store.state.requests.push(source) // 将该实例添加到队列中
    // do something before request is sent
    if (localStorage.balance > 0 && store.getters.auth != 1) {
      const url = config.url.split('?')[0]
      if (url !== '/service-region-provider/v1/region' && url !== '/service-slurm-provider/v1/files' && url !== '/service-msg-provider/v1/messages/type/unread' && url !== '/service-bill-provider/v3/bill/indexPageData' && url !== '/service-appManage-provider/v1/desktop/app/list/person' && url !== '/service-auth-provider/v1/certify/url' && url !== '/service-auth-provider/v1/certify/check' && url !== '/service-auth-provider/user/info') {
        var CancelToken = axios.CancelToken
        const source = CancelToken.source() // 申明CancelToken,也可new CancelToken.source()实例一个
        config.cancelToken = source.token // 讲实例对象的token赋予该请求
        store.state.requests.push(source) // 将该实例添加到队列中
        source.cancel()
        location.reload()
      }
    }
    config.headers['projectID'] = 5726455318996758
    if (store.getters.token) {
      // config.headers['Authorization'] = sessionStorage.desktopToken
      config.headers['Authorization'] = getToken()
      sessionStorage.desktopToken = getToken()
    }
    if (window.localStorage.regionId === undefined) {
      // config.headers['regionid'] = 2863528223241011
      // window.localStorage.regionValue = 2863528223241011
    } else {
      config.headers['regionid'] = window.localStorage.regionId
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // let res = ''
    // if (process.env.VUE_APP_SECRET === 'prod') {
    //   res = JSON.parse(Decrypt(response.data))
    // } else {
    //   res = response.data
    // }
    if (getRouteName() === '个人信息' && res.meta.status === 467) {
      ElMessage.error(res.meta.msg)
    }
    // console.log(res.meta)
    if (res.meta === undefined) {
      if (res.code) {
        if (res.code !== 200 && res.code !== 201 && res.code !== 204 && res.code !== 456 && res.code !== 467 && res.code !== 404) {
          if (res.code === 401) {
            // to re-login
            ElMessageBox.confirm('Token 已过期，是否重新登录？', '确认注销', {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消'
              // type: 'warning'
            }).then(() => {
              store.dispatch('user/resetToken').then(() => {
                location.reload()
              })
            })
          }
          if (res.code === 500 && res.message === '秒传失败') {
            return res
          }
          ElMessage.error(res.message)
          return Promise.reject(new Error(res.message || 'Error'))
        } else {
          return res
        }
      } else {
        return res
      }
    } else {
      if (res.meta.status !== 200 && res.meta.status !== 201 && res.meta.status !== 204 && res.meta.status !== 455 && res.meta.status !== 456 && res.meta.status !== 467 && res.meta.status !== 10404) {
        // 401: token 错误;
        if (res.meta.status === 401) {
          // to re-login
          ElMessageBox.confirm('Token 已过期，是否重新登录？', '确认注销', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消'
            // type: 'warning'
          }).then(() => {
            store.dispatch('user/resetToken').then(() => {
              location.reload()
            })
          })
        }
        ElMessage.error(res.meta.msg)
        return Promise.reject(new Error(res.meta.msg || 'Error'))
      } else {
        return res
      }
    }
    // return res
  },
  error => {
    if (error.response.data.meta !== undefined) {
      if (error.response.data.meta.status === 401) {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
        return
      }
    }
    if (error.message !== undefined) {
      ElMessage({
        message: error.message,
        type: 'error',
        duration: 1 * 10000
      })
      return Promise.reject(error)
    }
    // console.log(error.response.data.meta.msg) // for debug
    // Message({
    //   message: error.response.data.meta.msg,
    //   type: 'error',
    //   duration: 1 * 1000
    // })
    // return Promise.reject(error)
  }
)

export default service
