import request from '@/utils/requestFile'
// 每片上传调用的接口
export function uploadFile(file) {
  return request({
    url: '/file/chunk',
    method: 'post',
    data: file
  })
}
// 获取id上传部分md5
export function chunkPercreate(file) {
  return request({
    url: window.localStorage.regionName + '/file/precreate',
    method: 'post',
    data: file
  })
}
// 合并接口
export function chunkFileMerge(data) {
  return request({
    url: window.localStorage.regionName + '/file/merge',
    method: 'post',
    data
  })
}
// 上传进度
export function getFileProgress(id) {
  return request({
    url: window.localStorage.regionName + '/file/progress?id=' + id,
    method: 'get'
  })
}

// 删除记录
export function deleteFileChunk(id) {
  return request({
    url: window.localStorage.regionName + '/file/chunk?id=' + id,
    method: 'delete'
  })
}
