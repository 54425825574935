<template>
  <div class="upload-records">
    <div class="dialog-container">
      <el-dialog
        v-model="formVisible"
        title="上传记录"
        :close-on-click-modal="false"
        :before-close="handleClose"
        width="470px"
      >
        <div v-loading="loadingDialog" class="dialog-content">
          <div id="recordsContent" class="records-content">
            <!-- 正在上传的 -->
            <div v-for="(item,i) in progressList" :key="i" class="records-item">
              <div class="records-detail flex-normal-style">
                <el-tooltip
                  class="box-item"
                  effect="light"
                  :content="item[1].name"
                  placement="top-start"
                >
                  <div class="records-name">{{ item[1].name }}</div>
                </el-tooltip>
                <div class="records-time">{{ item[1].update_time }}</div>
              </div>
              <div class="file-detail">
                共{{ item[1].size }}，<span v-show="upload.get(item[0])">上传中</span><span v-show="!upload.get(item[0])">暂停中</span>
              </div>
              <div class="progress-bar">
                <div class="progress-bar-outer">
                  <div class="progress-bar-inner" :style="{width:item[1].percent+'%' }" />
                </div>
                <div class="progress-bar-text">{{ item[1].percent }}%</div>
                <!-- <el-progress :percentage="item.progress" /> -->
              </div>
              <div class="records-detail progress-operate" style="padding-bottom:0">
                <svg-icon v-show="upload.get(item[0])" icon-class="pause-file" @click="handleClickBtn(item[1].uid)" />
                <svg-icon v-show="!upload.get(item[0])" icon-class="continue-file" @click="handleClickBtn(item[1].uid)" />
                <svg-icon style="margin-left:10px" icon-class="cancel-file" @click="handleCancelBtn(item[1].uid, item[1].md5)" />
              </div>
            </div>
            <!-- 上传进度条 -->
            <!-- <div id="upLoadProgress" class="upload-progress" /> -->
            <!-- 已上传的记录 -->
            <div v-for="(item,i) in recordsList" :key="i" class="records-item">
              <div class="records-detail flex-normal-style">
                <el-tooltip
                  class="box-item"
                  effect="light"
                  :content="item.filename"
                  placement="top-start"
                >
                  <div class="records-name">{{ item.filename }}</div>
                </el-tooltip>
                <div class="records-time">{{ item.update_time }}</div>
              </div>
              <div class="records-progress">
                <el-progress v-if="item.uploaded" :percentage="item.percentage" status="success" />
                <el-progress v-else :percentage="item.percentage" status="exception" />
                <!-- 重新上传 -->
                <div v-if="!item.uploaded" class="icon-box">
                  <el-upload
                    ref="uploadFile"
                    class="upload-demo"
                    action=""
                    :http-request="httpRequest"
                  >
                    <svg-icon icon-class="reupload" @click="reuploadHandle(item)" />
                  </el-upload>
                </div>
                <div class="icon-box">
                  <svg-icon icon-class="del" @click="deleteRecord(item)" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="recordsList.length===0&&uploadLength==0" class="no-data">
            <img src="@/assets/icon/no-upload.png" alt="暂无上传记录">
            <div class="text">暂无上传记录</div>
          </div>
        </div>
        <template v-if="recordsList.length!==0||uploadLength!=0" #footer>
          <span class="dialog-footer">
            <el-button
              plain
              @click="deleteAllRecords"
            >清除记录</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import SparkMD5 from 'spark-md5'
const chunkSize = 10 * 1024 * 1024
import axios from 'axios'
const CancelToken = axios.CancelToken
import store from '@/store'
import { deleteFileChunk, chunkPercreate, chunkFileMerge, getFileProgress } from '@/api/chunkFile'
import { ElMessageBox, ElMessage } from 'element-plus'
let isFileRecord = false // 为false清除记录后不再上传分片,放data里可能会有this指向问题
export default {
  name: 'UploadRecords',
  props: ['visible', 'records', 'percentage', 'uploadChoiceFile', 'path', 'loading', 'fileList'],
  data() {
    return {
      chunkPath: '',
      fileDetail: null,
      loadingDialog: true,
      formVisible: this.visible,
      recordsList: [],
      recordsListFrontBack: [],
      totalPercentage: 0,
      uploadLength: 0,
      uploadChoiceFilObj: null,
      upload: new Map(), // 上传文件 状态
      progressList: new Map(), // 上传进度条
      chunkList: new Map(),
      newChunkList: new Map(),
      reqFunc: new Map(), // 上传的方法
      md5TotalList: new Map(), // 总的md5暂存
      md5HalfList: new Map(), // 部分md5
      isComplete: new Map(),
      idList: new Map() // id
    }
  },
  watch: {
    visible(newValue, oldValue) {
      this.formVisible = newValue
    },
    records(newValue, oldValue) {
      this.recordsList = newValue
      if (this.progressList.size !== 0) {
        this.progressList.forEach((val, key, map) => {
          if (val.fileId !== undefined) {
            this.recordsList.forEach((item, i) => {
              if (val.fileId === item.id) {
                this.recordsList.splice(i, 1)
              }
            })
          }
        })
      }
    },
    percentage(newValue, oldValue) {
      this.totalPercentage = newValue.toFixed(0)
    },
    loading(newValue, oldValue) {
      // console.log(newValue)
      this.loadingDialog = newValue
    },
    progressList: {
      handler(val) {
        this.uploadLength = val.size
        localStorage.uploadFileLength = val.size
      },
      deep: true
    },
    uploadChoiceFile: {
      handler(val) {
        this.uploadChoiceFilObj = val
        val.forEach(unit => {
          if (!this.recordsListFrontBack.includes(unit.file.uid)) {
            this.recordsListFrontBack.push(unit.file.uid)
            this.upload.set(unit.file.uid, true) // 初始化的上传的文件 上传状态为 true
            this.handleChange(unit)
          }
        })
      },
      deep: true
    },
    path(newValue, oldValue) {
      // console.log(newValue, oldValue)
    }
  },
  methods: {
    reuploadHandle(val) {
      // console.log(val)
      this.fileDetail = val
    },
    // 重传
    async httpRequest(options) {
      // this.$emit('httpRequest', options)
      const fileMd5 = await this.getHalfFileMd5(options.file.slice(0, chunkSize), 1, 'half')
      if (options.file.size === this.fileDetail.file_size && fileMd5 === this.fileDetail.slice_md5) {
        this.getFileProgress(options)
      } else {
        ElMessage.warning('请重新选择该文件！')
      }
    },
    // 重传文件的进度
    getFileProgress(options) {
      getFileProgress(this.fileDetail.id).then((res) => {
        if (res.code === 200) {
          this.chunkPath = this.fileDetail.target_path
          const len = res.data.length
          if (len !== 0) {
            this.handleChange(options, len)
          } else {
            this.handleChange(options, 0)
          }
        }
      })
    },
    // 上传记录关闭
    handleClose() {
      this.$emit('handleCloseRecords')
    },
    // 清除单条记录
    deleteRecord(item) {
      ElMessageBox.confirm('确定要清除该条记录么？', '清除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteFileChunk(item.id).then((res) => {
            if (res.code === 200) {
              this.$emit('refreshList')
            }
          })
        })
        .catch(() => {
        })
    },
    // 清除记录
    deleteAllRecords() {
      const that = this
      ElMessageBox.confirm('确定要清除所有记录么？', '清除提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          store.commit('downLoadProgress/CLEAR_CANCELTOKEN')
          that.progressList.clear()
          isFileRecord = false

          setTimeout(() => {
            that.$nextTick(() => {
              that.$emit('deleteAllRecords')
            })
          }, 1000)
        })
        .catch(() => {
        })
    },
    async handleChange(file, uploadNum) {
      // console.log(uploadNum)
      if (!file) {
        return
      }
      // 获取文件并转成 ArrayBuffer 对象
      const fileObj = file.file
      const chunkList = [] // 保存所有切片的数组
      var chunkListLength
      if (fileObj.size === 0) {
        chunkListLength = 1
      } else {
        chunkListLength = Math.ceil(fileObj.size / chunkSize) // 计算总共多个切片
      }
      var size
      if (fileObj.size < 1024) {
        size = fileObj.size + 'B'
      } else if (fileObj.size < 1024 * 1024) {
        size = (fileObj.size / 1024).toFixed(1) + 'KB'
      } else if (fileObj.size < 1024 * 1024 * 1024) {
        size = (fileObj.size / 1024 / 1024).toFixed(1) + 'MB'
      } else {
        size = (fileObj.size / 1024 / 1024 / 1024).toFixed(1) + 'GB'
      }
      this.progressList.set(fileObj.uid, { path: uploadNum ? this.chunkPath : this.path, 'percent': uploadNum ? Math.floor(100 / chunkListLength * (uploadNum - 1)) : 0, 'percentCount': 0, 'percentReal': uploadNum ? Math.floor(100 / chunkListLength * (uploadNum - 1)) : 0, size: size, name: fileObj.name, uid: fileObj.uid, update_time: this.formatUploadTime(new Date()) })
      // 获取文件的md5
      var fileMd5
      // fileMd5 = await this.getFileMd5(fileObj, chunkListLength, 'all')
      // 文件大于100M，则计算部分md5，总md5后传 小于则不处理
      if (fileObj.size > 10 * 1024 * 1024) {
        try {
        // buffer = await this.fileToBuffer(fileObj)
          this.getFileMd5(fileObj, chunkListLength, 'all')
          fileMd5 = await this.getHalfFileMd5(fileObj.slice(0, chunkSize), 1, 'half')
          this.md5HalfList.set(fileObj.uid, fileMd5)
        } catch (e) {
          // console.log(e)
        }
      } else {
        try {
        // buffer = await this.fileToBuffer(fileObj)
          fileMd5 = await this.getFileMd5(fileObj, chunkListLength, 'all')
          this.md5HalfList.set(fileObj.uid, fileMd5)
        } catch (e) {
          // console.log(e)
        }
      }
      var index = 0
      if (uploadNum !== undefined) {
        index = uploadNum
        this.handleClickBtn(fileObj.uid)
      }
      const data = {
        slice_md5: fileMd5,
        filename: fileObj.name,
        file_size: fileObj.size,
        target_path: uploadNum ? this.chunkPath : this.path,
        total_chunks: chunkListLength
      }
      if (uploadNum === undefined) {
        chunkPercreate(data).then(async(res) => {
          if (res.code === 200) {
            var file_id = res.data.id
            this.progressList.set(fileObj.uid, { fileId: file_id, path: uploadNum ? this.chunkPath : this.path, 'percent': uploadNum ? Math.floor(100 / chunkListLength * (uploadNum - 1)) : 0, 'percentCount': 0, 'percentReal': uploadNum ? Math.floor(100 / chunkListLength * (uploadNum - 1)) : 0, size: size, name: fileObj.name, uid: fileObj.uid, md5: fileMd5, update_time: this.formatUploadTime(new Date()) })
            this.$emit('changeRecordsListLoading', false)
            this.idList.set(fileObj.uid, file_id)
            if (this.md5TotalList.get(file.uid) != undefined) {
            //   // 调用合并接口 并从map里面删除
              this.mergeHandle(this.idList.get(file.uid), this.md5TotalList.get(file.uid), file.uid)
            }
            // 生成切片，这里后端要求传递的参数为字节数据块（chunk）和每个数据块的文件名（fileName）
            for (let i = index; i < chunkListLength; i++) {
            // 分片开始位置
              const start = i * chunkSize
              // 分片结束位置
              const end = Math.min(fileObj.size, start + chunkSize)
              // 取文件指定范围内的byte，从而得到分片数据
              var chunkFile
              if (i + 1 === chunkListLength) {
                chunkFile = fileObj.slice(start, end + 1)
              } else {
                chunkFile = fileObj.slice(start, end)
              }
              const item = {
                chunk: chunkFile,
                fileName: fileObj.name,
                md5: fileMd5,
                total_chunks: chunkListLength,
                path: uploadNum ? this.chunkPath : this.path,
                chunk_num: i + 1,
                uid: fileObj.uid,
                file_id: file_id
              }
              chunkList.push(item)
            }
            // this.$store.commit('downLoadProgress/SET_UPLOADFUNC', { 'funcArray': chunkList, 'uid': fileObj.uid })
            this.chunkList.set(fileObj.uid, chunkList) // sendRequest 要用到
            this.newChunkList.set(fileObj.uid, chunkList)
            this.newChunkList.set(fileObj.uid, JSON.parse(JSON.stringify(this.newChunkList.get(fileObj.uid))))
            await this.sendRequest(true, fileObj.uid)
          }
        })
      } else {
        var file_id = this.fileDetail.id
        this.recordsList.forEach((item, i) => {
          if (this.fileDetail.id === item.id) {
            this.recordsList.splice(i, 1)
          }
        })
        this.progressList.set(fileObj.uid, { fileId: file_id, path: uploadNum ? this.chunkPath : this.path, 'percent': uploadNum ? Math.floor(100 / chunkListLength * (uploadNum - 1)) : 0, 'percentCount': 0, 'percentReal': uploadNum ? Math.floor(100 / chunkListLength * (uploadNum - 1)) : 0, size: size, name: fileObj.name, uid: fileObj.uid, md5: fileMd5, update_time: this.formatUploadTime(new Date()) })
        this.$emit('changeRecordsListLoading', false)
        this.idList.set(fileObj.uid, file_id)
        if (this.md5TotalList.get(file.uid) != undefined) {
          //   // 调用合并接口 并从map里面删除
          this.mergeHandle(this.idList.get(file.uid), this.md5TotalList.get(file.uid), file.uid)
        }
        // 生成切片，这里后端要求传递的参数为字节数据块（chunk）和每个数据块的文件名（fileName）
        for (let i = index; i < chunkListLength; i++) {
          // 分片开始位置
          const start = i * chunkSize
          // 分片结束位置
          const end = Math.min(fileObj.size, start + chunkSize)
          // 取文件指定范围内的byte，从而得到分片数据
          var chunkFile
          if (i + 1 === chunkListLength) {
            chunkFile = fileObj.slice(start, end + 1)
          } else {
            chunkFile = fileObj.slice(start, end)
          }
          const item = {
            chunk: chunkFile,
            fileName: fileObj.name,
            md5: fileMd5,
            total_chunks: chunkListLength,
            path: uploadNum ? this.chunkPath : this.path,
            chunk_num: i + 1,
            uid: fileObj.uid,
            file_id: file_id
          }
          chunkList.push(item)
        }
        // this.$store.commit('downLoadProgress/SET_UPLOADFUNC', { 'funcArray': chunkList, 'uid': fileObj.uid })
        this.chunkList.set(fileObj.uid, chunkList) // sendRequest 要用到
        this.newChunkList.set(fileObj.uid, chunkList)
        this.newChunkList.set(fileObj.uid, JSON.parse(JSON.stringify(this.newChunkList.get(fileObj.uid))))
        await this.sendRequest(true, fileObj.uid)
      }
    },
    // 发送请求
    sendRequest(isFirst, uid) {
      if (isFirst) {
        var that = this
        const requestList = [] // 请求集合
        that.chunkList.get(uid).forEach((item, index) => {
          const fn = () => {
            const formData = new FormData()
            formData.append('chunk', item.chunk)
            formData.append('chunk_num', item.chunk_num)
            formData.append('id', item.file_id)
            return axios({
              url: '/resource/' + window.localStorage.regionName + '/file/chunk',
              method: 'post',
              data: formData,
              headers: {
                regionid: window.localStorage.regionId,
                projectID: 5726455318996758,
                Authorization: sessionStorage.desktopToken
              },
              // 关键代码 cancelToken
              cancelToken: new CancelToken(cancel => {
                store.commit('downLoadProgress/ADD_CANCELTOKEN', cancel)
              })
            }).then(res => {
              if (res.data.code === 200) { // 成功
                if (that.progressList.get(uid).percentCount === 0) { // 避免上传成功后会删除切片改变 chunkList 的长度影响到 percentCount 的值
                  // that.progressList.get(uid).percentCount = 100 / that.chunkList.get(uid).length // Math.floor()
                  that.progressList.get(uid).percentCount = 100 / that.chunkList.get(uid)[0].total_chunks
                }
                that.progressList.get(uid).percentReal += that.progressList.get(uid).percentCount // 改变进度
                that.progressList.get(uid).percent = Math.floor(that.progressList.get(uid).percentReal)
                if (that.progressList.get(uid).percent > 100) {
                  that.progressList.get(uid).percent = 100
                }
                // that.chunkList.splice(index, 1) // 一旦上传成功就删除这一个 chunk，方便断点续传
                that.newChunkList.get(uid).splice(0, 1)
                // if (item.chunk_num === item.total_chunks) {

                // }
              } else {
                ElMessage.error(res.data.message)
                that.progressList.get(uid).percent = 100
                this.progressList.delete(uid)
                that.$emit('refreshList')
              }
            }).catch(() => {
              that.progressList.delete(uid)
              // that.progressList.get(uid).percent = 100
              // that.$emit('refreshList')
            })
          }
          requestList.push(fn)
        })
        this.reqFunc.set(uid, requestList)
      }
      isFileRecord = true
      const send = async() => {
        // 清除了记录
        if (!isFileRecord) {
          return
        }
        if (!this.upload.get(uid)) {
          // 暂停的情况
          return
        }
        if (this.reqFunc.get(uid).length == 0) {
          // 上传完 md5还未计算完
          if (this.md5TotalList.get(uid) == undefined) {
            this.isComplete.set(uid, false)
          } else {
            this.mergeHandle(this.idList.get(uid), this.md5TotalList.get(uid), uid)
            // md5计算完成的情况，调用接口并从map里面删除
          }
          // 发送完毕
          return
        }
        const func = this.reqFunc.get(uid).shift()
        await func()
        send()
      }

      send() // 发送请求
    },
    // 合并接口
    mergeHandle(id, md5, uid) {
      const data = {
        id: id,
        md5: md5
      }
      chunkFileMerge(data).then((res) => {
        if (res.code === 200) {
          this.progressList.get(uid).percent = 100
          ElMessage.success('上传成功')
          this.progressList.delete(uid)
          this.$emit('refreshList')
          this.isComplete.delete(uid)
          this.reqFunc.delete(uid)
        }
      })
    },
    // 如果取消
    handleCancelBtn(uid, hash) {
      if (this.upload.get(uid)) {
        this.handleClickBtn(uid)
      }
      this.progressList.delete(uid)
      this.$emit('refreshList')
      // this.cancelUploadFile(uid, hash)
    },
    // 取消正在上传的文件
    cancelUploadFile(uid, md5) {
      const data = {
        md5: md5
      }
      deleteFileChunk(data)
        .then((res) => {
          if (res.data.code !== 200) {
            ElMessage.error(res.data.message)
          } else {
            // 删除progress的数据
            this.progressList.delete(uid)
            this.$nextTick(() => {
              // this.cancel('已取消')
              this.$emit('refreshList')
            })
          }
        })
    },
    // 按下暂停按钮
    handleClickBtn(uid) {
      // console.log(this.upload.get(uid))
      this.upload.set(uid, !this.upload.get(uid))
      // 如果不暂停则继续上传
      if (this.upload.get(uid)) {
        this.sendRequest(false, uid)
      }
    },

    // 将 File 对象转为 ArrayBuffer
    // 获取文件MD5
    getFileMd5(file, chunkCount, status) {
      return new Promise((resolve, reject) => {
        const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
        const chunks = chunkCount
        let currentChunk = 0
        const spark = new SparkMD5.ArrayBuffer()
        const fileReader = new FileReader()
        const that = this
        fileReader.onload = function(e) {
          spark.append(e.target.result)
          currentChunk++
          if (currentChunk < chunks) {
            loadNext()
          } else {
            const md5 = spark.end()
            if (status === 'all') {
              that.md5TotalList.set(file.uid, md5)
            }
            if (that.idList.get(file.uid) != undefined) {
            // 调用合并接口 并从map里面删除
              that.mergeHandle(that.idList.get(file.uid), that.md5TotalList.get(file.uid), file.uid)
            }
            resolve(md5)
          }
        }
        fileReader.onerror = function(e) {
          reject(e)
        }
        function loadNext() {
          const start = currentChunk * chunkSize
          let end = start + chunkSize
          if (end > file.size) {
            end = file.size
          }
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
        }
        loadNext()
      })
    },
    // 将 File 对象转为 ArrayBuffer
    // 获取文件MD5
    getHalfFileMd5(file, chunkCount) {
      return new Promise((resolve, reject) => {
        const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
        const chunks = chunkCount
        let currentChunk = 0
        const spark = new SparkMD5.ArrayBuffer()
        const fileReader = new FileReader()
        const that = this
        fileReader.onload = function(e) {
          spark.append(e.target.result)
          currentChunk++
          if (currentChunk < chunks) {
            loadNext()
          } else {
            const md5 = spark.end()
            resolve(md5)
          }
        }
        fileReader.onerror = function(e) {
          reject(e)
        }
        function loadNext() {
          const start = currentChunk * chunkSize
          let end = start + chunkSize
          if (end > file.size) {
            end = file.size
          }
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
        }
        loadNext()
      })
    },
    // 时间格式化
    formatUploadTime(data) {
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    }
  }
}
</script>

<style lang="less" scoped>
.upload-records{
  :deep .el-dialog__headerbtn{
    top: 8px;
  }
  :deep .el-dialog__footer{
    padding: 0 16px 16px;
  }
  .progress-bar{
    display:flex;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    // margin-top: 16px;
    margin-bottom: 12px;
  }
 .progress-bar-outer{
    width: 344px;
    border-radius: 100px;
    background-color: #E7E7E7;
    height:6px;
    align-items: center;
    display: flex;
 }
 .progress-bar-inner{
    border-radius: 100px;
    height: 100%;
    transition: width 0.6s ease;
    text-align: right;
    line-height: 80%;
    background-color: #437AEC;
 }
 .progress-bar-text{
  padding-left: 8px;
  font-size: 14px;
  color: #000;
 }
  .dialog-content{
    padding: 8px 16px 15px;
    max-height: 500px;
    overflow: auto;
    .no-data{
      text-align: center;
      padding: 30px;
      .text{
        color: #606266;
        font-size: 14px;
        padding-top: 30px;
      }
    }
  .records-item{
    padding: 16px 18px 16px 24px;
    border-bottom: 1px solid #E7E7E7;
    .records-progress{
      display: flex;
      align-items: center;
      .icon-box{
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
      .svg-icon{
        width: 16px;
        height: 16px;
      }
      ::v-deep .is-exception{
        width: 346px;
        .el-progress__text{
          display: none;
        }
      }
    }
    ::v-deep .el-icon{
      svg{
        width: 16px;
        height: 16px;
      }
    }
    ::v-deep .el-progress.is-success .el-progress-bar__inner{
      background-color: #0DB57D;
    }
    ::v-deep .el-progress.is-success .el-progress__text,::v-deep .el-progress.is-success .el-icon{
      color: #0DB57D;
      font-size: 16px !important;
    }
    ::v-deep .el-progress.is-warning .el-progress-bar__inner{
      background-color: #ED7B2F;
    }
    ::v-deep .el-progress.is-warning .el-progress__text,::v-deep .el-progress.is-warning .el-icon{
      color: #ED7B2F;
      font-size: 16px !important;
    }
    ::v-deep .el-progress{
      width: 368px;
    }
    ::v-deep .el-progress__text{
      min-width: 0 !important;
    }
    ::v-deep .el-progress-bar__outer {
      width: 344px !important;
    }
    .records-detail{
      padding-bottom: 8px;
      font-size: 14px;
      line-height: 22px;
      color: #303133;
      .svg-icon{
        width: 18px;
        height: 18px;
      }
      .records-name{
        width: 200px;
        height: 22px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
      }
      .records-time{
        color: #909399;
        padding-right: 5px;
      }
    }
    .file-detail{
      line-height: 22px;
      color: #606266;
      font-size: 14px;
      padding-bottom: 8px;
    }
  }
  }
}
::v-deep .el-dialog{
  border-radius: 20px;
}
  ::v-deep .el-button{
    min-height: 32px;
    padding: 5px 16px;
    border: 1px solid #F02E3E !important;
    color: #F02E3E !important;
  }
::v-deep .el-dialog__header{
      border-bottom: 1px solid #E7E7E7;
      background: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      text-align: center;
    }
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-icon{
      color: #333;
    }
    ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
</style>
